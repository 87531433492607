import SupernovaApi from "api/SupernovaApi";
import { EventType } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import EditModal from "./EditModal";
import { IcebergBadge, IconButton } from "@tradesolution/iceberg-ui-react";
import { format } from "date-fns";
import CreateModal from "./CreateModal";
import { useNavigate } from "react-router-dom";
import TjenestePicker from "components/TjenestePicker";
import useToaster from "framework/Toaster/useToaster";
import CommonLoader from "components/CommonLoader";

const EventTypes = () => {

    const navigate = useNavigate();
    const toaster = useToaster();

    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [selectedEventType, setSelectedEventType] = useState<EventType | undefined>(undefined);

    const [source, setSource] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilteringLoading, setIsFilteringLoading] = useState<boolean>(false);
    const [isSearchingLoading, setIsSearchingLoading] = useState<boolean>(false);


    const handleTjenesteSelected = (s: string): void => {
        setIsFilteringLoading(true);
        setSource(s)
        setTimeout(() => setIsFilteringLoading(false), 2000); 
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsSearchingLoading(true);
        setFilter(e.target.value.toLowerCase());
        setTimeout(() => {
            setIsSearchingLoading(false);
        }, 2000); 
    }


    const loadEventTypes = async () => {
        setIsLoading(true);
        const s = await SupernovaApi.eventTypes.getEventTypes();
        setIsLoading(false);
        setEventTypes(s);
    };

    useEffect(() => {
        loadEventTypes();
    }, []);

    const handleEventTypeSelected = (ev: EventType) => {
        setSelectedEventType(ev);
    };

    const handleEditClose = () => {
        setSelectedEventType(undefined);
        loadEventTypes();
    }

    const formatLastProcessed = (date?: Date) => {
        if (!date) return "";
        return `Sist prosessert: ${format(date, 'dd.MM.yyyy')}`;
    };

    const [filter, setFilter] = useState<string>("");

    const [showAddEventTypeModal, setShowAddEventTypeModal] = useState<boolean>(false);

    const showAddEventType = () => {
        setShowAddEventTypeModal(true);
    };

    const handleAddClose = () => {
        setShowAddEventTypeModal(false);
        loadEventTypes();
    }

    const handleToggleDelayedEmail = async (id: string): Promise<void> => {
        const result = await SupernovaApi.eventTypes.toggleRecommendedForDelayedEmailDelivery(id);
        if (result.success) {
            toaster.success("Oppdatert");
            // Update the specific event type in state
            setEventTypes(currentEventTypes => {
                return currentEventTypes.map(eventType => {
                    if (eventType.id === id) {
                        return { ...eventType, recommendedForDelayedEmailDelivery: !eventType.recommendedForDelayedEmailDelivery };
                    }
                    return eventType;
                });
            });
        } else {
            toaster.error("Noe gikk galt");
        }
    }

    const handleToggleImidiateEmail = async (id: string): Promise<void> => {
        const result = await SupernovaApi.eventTypes.toggleRecommendedForImidiateEmailDelivery(id);
        if (result.success) {
            toaster.success("Oppdatert");
            // Update the specific event type in state
            setEventTypes(currentEventTypes => {
                return currentEventTypes.map(eventType => {
                    if (eventType.id === id) {
                        return { ...eventType, recommendedForImidiateEmailDelivery: !eventType.recommendedForImidiateEmailDelivery };
                    }
                    return eventType;
                });
            });
        } else {
            toaster.error("Noe gikk galt");
        }
    }

    const handleToggleSms = async (id: string): Promise<void> => {
        const result = await SupernovaApi.eventTypes.toggleRecommendedForSmsDelivery(id);
        if (result.success) {
            toaster.success("Oppdatert");
            // Update the specific event type in state
            setEventTypes(currentEventTypes => {
                return currentEventTypes.map(eventType => {
                    if (eventType.id === id) {
                        return { ...eventType, recommendedForSmsDelivery: !eventType.recommendedForSmsDelivery };
                    }
                    return eventType;
                });
            });
        }else {
            toaster.error("Noe gikk galt");
        }
    }

    const handleToggleRecommendSubscription = async (id: string): Promise<void> => {
        const result = await SupernovaApi.eventTypes.toggleNotAvailableForSourceSubscriptions(id);
        if (result.success) {
            toaster.success("Oppdatert");
            // Update the specific event type in state
            setEventTypes(currentEventTypes => {
                return currentEventTypes.map(eventType => {
                    if (eventType.id === id) {
                        return { ...eventType, notAvailableForSourceSubscriptions: !eventType.notAvailableForSourceSubscriptions };
                    }
                    return eventType;
                });
            });
        } else {
            toaster.error("Noe gikk galt");
        }
    };


    const redirectToSubscribersPage = (eventType: EventType) => {
        navigate(`/eventTypes/${eventType.id}/subscribers`);
    };

    const getFilteredEventTypes = (): EventType[] => {
        let result = eventTypes.sort((a, b) => a.displayName.localeCompare(b.displayName));
        if (source) {
            result = result.filter(x => x.source === source);
        }
        if (filter) {
            result = result.filter(x => x.displayName.toLowerCase().includes(filter));
        }
        return result
    }

    const getDistributionStateDisplayName = (distributionState: number) : string => {
        switch (distributionState) {
            case 0:
                return "Kun internt";
            case 1:
                return "Offentlig";
            case 2:
                return "Begrenset";
            default:
                return "Ukjent";
        }
    }

    return (
        <PageWrapper
            title="Event typer"
            rightContent={
                <IconButton icon="plus" variant="primary" onClick={showAddEventType}>Ny event type</IconButton>
            }
        >
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Control type="text" placeholder="Søk" value={filter} onChange={handleSearch} />
                    </Form.Group>
                </Col>
                <Col className="text-end">
                    <TjenestePicker selectedSource={source}  onChange={handleTjenesteSelected} />
                </Col>
            </Row>
            {(isLoading || isFilteringLoading || isSearchingLoading) && (
                <Row>
                    <Col>
                        <CommonLoader/>
                    </Col>
                </Row>
            )}

            {!isFilteringLoading && !isLoading && !isSearchingLoading && eventTypes.length === 0 && (
                <Row>
                    <Col>
                        <p>Ingen event funnet!</p>
                    </Col>
                </Row>
            )}
            {!isFilteringLoading && !isLoading && !isSearchingLoading && eventTypes.length > 0 && (
                <Table className="tsTable" hover responsive>
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th className="text-center">Anbefalt varsel</th>
                            <th className="text-center">Oppsamlingsepost</th>
                            <th className="text-center">Epost umiddelbart</th>
                            <th className="text-center">Sms</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getFilteredEventTypes().filter(o => o.displayName.toLowerCase().includes(filter) || o.source.toLowerCase().includes(filter)).map((x, i) => (
                            <tr key={i}>
                                <td>
                                    <strong style={{ fontWeight: 600 }}>{x.displayName}</strong> - {x.source}<br />
                                    <div style={{ fontSize: "smaller" }}>
                                        <span><IcebergBadge variant="primary">{getDistributionStateDisplayName(x.distributionState)}</IcebergBadge></span>
                                        {formatLastProcessed(x.lastEventProcessedAt)}
                                    </div>
                                </td>
                                <td className="text-center">
                                    <Form.Check checked={!x.notAvailableForSourceSubscriptions} onChange={() => handleToggleRecommendSubscription(x.id)} />
                                </td>
                                <td className="text-center">
                                    <Form.Check checked={x.recommendedForDelayedEmailDelivery} onChange={() => handleToggleDelayedEmail(x.id)} />
                                </td>
                                <td className="text-center">
                                    <Form.Check checked={x.recommendedForImidiateEmailDelivery} onChange={() => handleToggleImidiateEmail(x.id)} />
                                </td>
                                <td className="text-center">
                                    <Form.Check checked={x.recommendedForSmsDelivery} onChange={() => handleToggleSms(x.id)} />
                                </td>
                                <td className="fitContent">
                                    <IconButton icon="envelope" size="sm" variant="outline-primary" onClick={() => redirectToSubscribersPage(x)}>Abonnenter</IconButton>
                                </td>
                                <td className="fitContent">
                                    <IconButton icon="edit" size="sm" variant="outline-primary" onClick={() => handleEventTypeSelected(x)}>Endre</IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            {selectedEventType && <EditModal eventType={selectedEventType} onClose={handleEditClose} />}
            <CreateModal show={showAddEventTypeModal} onClose={handleAddClose} />
        </PageWrapper>
    );
};

export default EventTypes;